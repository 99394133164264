import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Clock from "../icons/Clock"
import formatDate from "@util/formatDate"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  let { edges: mdxPosts } = data.allMdx
  const isProduction = process.env.NODE_ENV === "production"
  if (isProduction) {
    mdxPosts = mdxPosts.filter(({ node: post }) => !post?.frontmatter?.draft)
  }
  mdxPosts = mdxPosts.sort(({ node: post1 }, { node: post2 }) =>
    post2?.frontmatter?.date > post1?.frontmatter?.date ? 1 : -1
  )

  if (mdxPosts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio withHeaderImage />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} className="bg-white-50">
      <SEO title="All posts" />
      <Bio className="bg-white" withHeaderImage />
      <div className="bg-gray-50">
        <ol
          className="grid gap-4 pt-4 m-auto max-content-width px-4"
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(18rem, 1fr))",
          }}
        >
          {mdxPosts.map(({ node: post }, i) => {
            const featuredImageFit =
              post.frontmatter.featuredImageFit || "cover"
            const title = post.frontmatter.title || post.fields.slug
            const isDraft = post.frontmatter.draft

            const newest = i === 0
            let badge
            if (newest) {
              badge = (
                <div className="badge badge-green">
                  New{" "}
                  <span role="img" aria-label="party popper">
                    🎉
                  </span>
                </div>
              )
            }
            console.log("post.frontmatter.hot", "post.frontmatter.hot")
            if (post.frontmatter.hot) {
              badge = (
                <div className="badge badge-orange">
                  Trending{" "}
                  <span role="img" aria-label="sparkles">
                    🔥
                  </span>
                </div>
              )
            }
            if (post.frontmatter.bestOf) {
              badge = (
                <div className="badge badge-blue">
                  Best of{" "}
                  <span role="img" aria-label="sparkles">
                    ✨
                  </span>
                </div>
              )
            }

            return (
              <li key={post.fields.slug}>
                <Link to={post.fields.slug}>
                  <article
                    itemScope
                    itemType="http://schema.org/Article"
                    className="h-full flex flex-col bg-white border border-gray-100 shadow-md rounded-xl overflow-hidden hover:shadow-2xl"
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fill, minmax(250px, 1fr))",
                    }}
                  >
                    <Img
                      fluid={
                        post.frontmatter.featuredImage?.childImageSharp?.fluid
                      }
                      className="max-w-full"
                      style={{
                        objectFit: `${featuredImageFit}!important`,
                        height: "15rem",
                      }}
                    />

                    <section
                      style={{
                        border:
                          isDraft && !isProduction && "0.25rem solid #22C55E",
                        boxSizing: isDraft && !isProduction && "border-box",
                      }}
                      className="px-4 py-2 flex flex-col flex-grow hover:text-blue-500 text-black"
                    >
                      <div>
                        <h2 className="font-semibold text-md mt-4">
                          <span itemProp="headline">{title}</span>
                        </h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post.frontmatter.description || post.excerpt,
                          }}
                          itemProp="description"
                          className="text-gray-500"
                        />
                      </div>
                      <div className="flex mt-auto justify-between items-center">
                        <div className="flex items-center text-gray-500">
                          <Clock className="h-4" />
                          &nbsp;
                          <small>{formatDate(post.frontmatter.date)}</small>
                        </div>
                        {false && <small>{post.frontmatter.timeToRead}</small>}
                        {badge}
                      </div>
                    </section>
                  </article>
                </Link>
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      edges {
        node {
          id
          excerpt

          frontmatter {
            date
            description
            draft
            hot
            title
            bestOf
            timeToRead
            featuredImageFit

            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
